<template>
  <div class="column-chart">
    <div>
      <div
        v-if="!tableChartData.length && filteredProjectsForCharts.length"
        class="no-data"
      >
        There is no data in this period
      </div>
    </div>
    <GChart
      v-if="formatColumnChartData.length && namesProject.length"
      :settings="{packages: ['bar']}"
      :data="chartData"
      :options="chartOptions"
      :create-chart="(el, google) => new google.charts.Bar(el || $el)"
      @ready="onChartReady"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { GChart } from 'vue-google-charts';
import { mapGetters } from 'vuex';

export default {
  name: 'ColumnChart',
  components: {
    GChart,
  },

  data: () => ({
    chartsLib: null,
  }),

  computed: {
    ...mapGetters([
      'dateForChart',
      'columnChartData',
      'filteredProjectsForCharts',
      'tableChartData',
    ]),
    duration() {
      const duration = moment.duration(moment(this.dateForChart[1])
        .diff(moment(this.dateForChart[0])));

      return Math.round(moment.duration(duration, 'milliseconds').asDays());
    },
    formatColumnChartData() {
      if (moment(this.dateForChart[0]).format('YYYY-MM-DD')
        === moment(this.dateForChart[1]).format('YYYY-MM-DD')) {
        return this.columnChartData.map(el => {
          el[0] = moment(el[0]).format('h:00');

          return el;
        });
      }
      if (this.duration > 1 && this.duration <= 7) {
        return this.columnChartData.map(el => {
          el[0] = moment(el[0]).format('D MMM, ddd');

          return el;
        });
      }
      if (this.duration > 7 && this.duration <= 15) {
        return this.columnChartData.map(el => {
          el[0] = moment(el[0]).format('D MMM');

          return el;
        });
      }
      if (this.duration > 15 && this.duration <= 31) {
        return this.columnChartData.map(el => {
          el[0] = moment(el[0]).format('Do');

          return el;
        });
      }
      if (this.duration > 31 && this.duration < 365) {
        return this.columnChartData.map(el => {
          el[0] = moment(el[0]).format('D MMM');

          return el;
        });
      }
      if (this.duration >= 365) {
        return this.columnChartData.map(el => {
          el[0] = moment(el[0]).format('MMM');

          return el;
        });
      }

      return '';
    },
    namesProject() {
      return this.filteredProjectsForCharts.map(a => a.name);
    },
    chartData() {
      return [
        ['date', ...this.namesProject],
        ...this.formatColumnChartData,
      ];
    },
    chartOptions() {
      if (!this.chartsLib) return null;

      return this.chartsLib.charts.Bar.convertOptions({
        title: 'Projects Performance',
        backgroundColor: 'transparent',
        titleTextStyle: {
          color: '#666F7A',
          fontSize: 16,
        },
        colors: ['#6D71F0', '#EA4C87', '#ec8f6e', '#31D78A', '#f6c7b6'],
        legend: {
          position: 'top',
          alignment: 'start',
        },
        tooltip: {
          text: 'value',
          trigger: 'focus',
          textStyle: {
            color: '#666F7A',
            fontSize: 12,
          },
        },
        hAxis: {
          textStyle: {
            color: '#666F7A',
            // fontSize: '100%',
          },
          title: 'Time interval',
          titleTextStyle: {
            color: '#666F7A',
          },
        },
        vAxis: {
          format: 'decimal',
          textStyle: {
            color: '#666F7A',
          },
          // title: 'Number of hours',
          titleTextStyle: {
            color: '#666F7A',
          },
          minValue: 0,
        },
      });
    },
  },

  methods: {
    onChartReady(chart, api) {
      this.chartsLib = api;
    },
  },
};
</script>

<style scoped>
  .column-chart {
    margin-top : 20px;
    position   : relative;
  }

  .no-data {
    position        : absolute;
    top             : 0;
    left            : 0;
    right           : 0;
    bottom          : 0;
    display         : grid;
    justify-content : center;
    align-items     : center;
    color           : #666F7A;
    font-size       : 18px;
    margin-left     : 100px;
    margin-bottom   : 18px;
  }
</style>
